import {Component, OnInit, AfterViewInit, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {SystemLanguageDTO} from "../services/restdto/SystemLanguageDTO";
declare let M;
declare let $;
@Component({
  selector: 'app-languageselectbox',
  templateUrl: './languageselectbox.component.html',
  styleUrls: ['./languageselectbox.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class LanguageselectboxComponent implements OnInit, AfterViewInit {

  @Input()
  defaultLang: string;

  @Input()
  availableLangs: SystemLanguageDTO[];

  @Input()
  disabled: boolean;

  defaultSelectedLang: SystemLanguageDTO;

  @Output()
  onSelectInstrumentLanguage = new EventEmitter();

  constructor() {}

  ngOnInit() {
    for (let systemLang of this.availableLangs) {
      if (systemLang.languageCode === this.defaultLang) {
        this.defaultSelectedLang = systemLang;
        console.log("Found default lang: "+systemLang.languageCode+" for item "+this.defaultSelectedLang.languageCode+" in the list: "+JSON.stringify(this.availableLangs));
        break;
      }
    }
  }

  ngAfterViewInit() {
    //setup the controls
    M.FormSelect.init($('select') , {})
  }

  selectedInstrumentLanguage(selectedOptionValue: string) {
    console.log("Let us emit the onChange of the language: "+selectedOptionValue);
    this.onSelectInstrumentLanguage.emit(selectedOptionValue);
  }
}
