import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SpinnerOverlayService } from '../services/spinneroverlay.service';
import { MytasksService } from '../services/mytasks.service';
import { MytasksFrontpageResponse } from '../services/restdto/MytasksFrontpageResponse';
import { FetchMyTasksInstrumentResponse } from "../services/restdto/FetchMyTasksInstrumentResponse";
import { StartMyTasksInstrumentResponse } from "../services/restdto/StartMyTasksInstrumentResponse";

import { ProjectInstrumentDTO } from "../services/restdto/ProjectInstrumentDTO";
import { GetExternalStatusResponse } from "../services/restdto/GetExternalStatusResponse";
import { ResponseBasis } from "../services/restdto/ResponseBasis";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-instrument',
  templateUrl: './instrument.component.html',
  styleUrls: ['./instrument.component.css']
})
export class InstrumentComponent implements OnInit {

  @Input() taskHash: string;

  @Input() queryInstrumentId: string;

  @Input() queryErrorCode: string;

  @Input() isAdmin: boolean;

  @Output()
  onBioDataStatusLoaded = new EventEmitter();

  mytasksFrontpageResponse: MytasksFrontpageResponse;

  fetchMyTasksInstrumentResponse: FetchMyTasksInstrumentResponse;

  startMyTasksInstrumentResponse: StartMyTasksInstrumentResponse;

  getExternalStatusResponse: GetExternalStatusResponse;

  errorMessageMapper: object = {};

  errorText: string;

  constructor(private spinner: SpinnerOverlayService, private mytaskServices: MytasksService) {
  }

  ngOnInit() {

    this.mytaskServices.getMyTaskInfo(this.taskHash).subscribe(
      (data) => {
        const pageupOnFailUrl = data.pageupOnFailUrl;
        const pageupOnCompletionUrl = data.pageupOnCompletionUrl;
        if (null == data || !data.succeeded) {
          // alert("Encountered an error ["+data.messageToDisplay+"] Please try again later. Now closing the page...");
          // window.self.close();
          // window.location.href = "https://savilleassessment.com.au";

          if (data.projectType !== 'PageUp') {
            // diplay error message for non-pageup project
            this.mytaskServices.errorMessage = data.messageToDisplay;
          } else {
            // redirect to pageup url for pageup project
            // window.self.close();
            window.location.href = pageupOnFailUrl;
          }
        }
        else {
          this.mytasksFrontpageResponse = data;
          this.mytaskServices.candidateFirstName = this.mytasksFrontpageResponse.candidateFirstName;
          this.mytaskServices.candidateLastName = this.mytasksFrontpageResponse.candiateLastName;
          this.mytaskServices.candidateEmail = this.mytasksFrontpageResponse.candidateEmail;
          this.mytaskServices.bioDataProvided = this.mytasksFrontpageResponse.bioDataProvided;
          this.mytaskServices.aboutHtml = this.mytasksFrontpageResponse.aboutHtml;
          this.mytaskServices.helpHtml = this.mytasksFrontpageResponse.helpHtml;
          this.mytaskServices.prepHtml = this.mytasksFrontpageResponse.prepHtml;
          this.mytaskServices.companyIconUrl = this.mytasksFrontpageResponse.companyIconUrl;

          this.onBioDataStatusLoaded.emit(this.mytasksFrontpageResponse.bioDataProvided);

          console.log(this.mytaskServices.candidateFirstName + " " + this.mytaskServices.candidateLastName + " " + this.mytaskServices.candidateEmail);

          if (null == this.mytasksFrontpageResponse.projectInstruments) {
            if (data.projectType !== 'PageUp') {
              return;
            } else {
              // redirect to pageup url for pageup project
              // window.self.close();
              window.location.href = pageupOnFailUrl;
            }
          }

          // *** COPE WITH THE ASSESSMENT EXCEPTIONS/ERRORS PARAMETERS
          if (data.projectType !== 'PageUp') {
            // Non-PageUp project
            for (let projectInstrument of this.mytasksFrontpageResponse.projectInstruments) {
              //to check the instrument error messages
              if (this.displayMessageBasedOnQueryString(projectInstrument.projectInstrumentId)) {
                //change the result to completed
                projectInstrument.assessmentStatus = "UiCompleted";

                //contact the server about changing the status to ui_complete
                this.mytaskServices.completeAssessmentViaDirectUrl(
                    this.taskHash, projectInstrument.projectInstrumentId).subscribe((data) => {
                  console.log("trying to complete assessment: " + JSON.stringify(data));
                });
              } else {
                if (projectInstrument.projectInstrumentId !== parseInt(this.queryInstrumentId)) {
                  //since the instrument id is not what we want, just skip it...
                  continue;
                }

                //we might encounter some errors, cope with it!
                if (null != this.queryErrorCode && this.queryErrorCode.trim() !== '') {
                  //contact the server about changing the status to error
                  this.mytaskServices.assessmentErrorViaDirectUrl(
                      this.taskHash, parseInt(this.queryInstrumentId.trim()), this.queryErrorCode).subscribe((data) => {
                    console.log("trying to make assessment error: " + JSON.stringify(data));
                    let errorRes: ResponseBasis = data;
                    //display the error message
                    this.errorMessageMapper[this.queryInstrumentId + ""] = errorRes.messageToDisplay;
                    //refresh the data structure
                    if (this.queryErrorCode == "15") { //Aborted
                      projectInstrument.assessmentStatus = "Aborted";
                    } else {
                      projectInstrument.assessmentStatus = "Error";
                      if (this.queryErrorCode == "14") {
                        projectInstrument.assessmentStatus = "InProgress";
                        this.errorText = "Already started...";
                      } else {
                        this.errorText = "Got errors, try again";
                      }
                    }
                  });
                }
              }
            }
          } else {
            // PageUp project
            for (let projectInstrument of this.mytasksFrontpageResponse.projectInstruments) {
              //to check the instrument error messages
              // no error
              if (this.displayMessageBasedOnQueryString(projectInstrument.projectInstrumentId)) {
                //change the result to completed
                projectInstrument.assessmentStatus = "UiCompleted";

                //contact the server about changing the status to ui_complete
                this.mytaskServices.completeAssessmentViaDirectUrl(
                    this.taskHash, projectInstrument.projectInstrumentId).subscribe((data) => {
                  console.log("trying to complete assessment: " + JSON.stringify(data));
                });
              } else {
                if (projectInstrument.projectInstrumentId !== parseInt(this.queryInstrumentId)) {
                  //since the instrument id is not what we want, just skip it...
                  continue;
                }

                //we might encounter some errors, cope with it!
                if (null != this.queryErrorCode && this.queryErrorCode.trim() !== '') {
                  //contact the server about changing the status to error
                  this.mytaskServices.assessmentErrorViaDirectUrl(
                      this.taskHash, parseInt(this.queryInstrumentId.trim()), this.queryErrorCode).subscribe((data) => {
                    console.log("trying to make assessment error: " + JSON.stringify(data));

                    // redirect to pageup url for pageup project
                    window.self.close();
                    window.location.href = pageupOnFailUrl;
                  });
                }
              }
            }

            if (this.mytasksFrontpageResponse.projectInstruments.filter(pi => pi.assessmentStatus === 'UiCompleted').length === this.mytasksFrontpageResponse.projectInstruments.length || this.mytasksFrontpageResponse.projectInstruments.filter(pi => pi.assessmentStatus === 'Completed').length === this.mytasksFrontpageResponse.projectInstruments.length) {
              // all completed for pageup project, redirect to completion url
              // redirect to pageup url for pageup project
              // window.self.close();
              window.location.href = pageupOnCompletionUrl;
            }
          }
        }

        this.spinner.hideOverlay();
      });
  }

  displayMessageBasedOnQueryString(instrumentId: number) {
    if (null == this.queryInstrumentId) {
      //just ignore that since it was the first time we are entering the my task page
      return false;
    }

    if (instrumentId === parseInt(this.queryInstrumentId.trim())) {
      if (null == this.queryErrorCode) {
        //that simply means candidate completed the assessment
        //request a update to the backend status as well
        return true;
      }
      else {
        //oops, we got some error from ui
        switch (this.queryErrorCode) {
          //customized mapping rules
          default:
            this.errorMessageMapper[this.queryInstrumentId + ""] = this.queryErrorCode;
            break;
        }
      }
    }
    else {
      //nothing happened for that particular assessment
    }

    return false;
  }

  // syncExtAssessmentStatus(myTaskHash: string, projectInstrumentId: number) {
  //   this.mytaskServices.syncExtAssessmentStatus(myTaskHash, projectInstrumentId).subscribe(
  //     (data) => {
  //       if (null != data && data.succeeded) {
  //         this.getExternalStatusResponse = data;
  //
  //         console.log("sync with external status: "+this.getExternalStatusResponse.status);
  //       }
  //       else {
  //         console.error("Something error: "+data.messageToDisplay);
  //         this.errorMessageMapper[projectInstrumentId+""] = data.messageToDisplay;
  //         this.spinner.hideOverlay();
  //       }
  //     });
  // }

  printErrorMessage(projectInstrumentId: number) {
    if (null != this.errorMessageMapper && null != this.errorMessageMapper[projectInstrumentId + ""]) {
      return this.errorMessageMapper[projectInstrumentId + ""];
    }
  }

  startSessionForInstrumentDirectly(myTaskHash: string, projectInstrumentId: number) {
    this.spinner.showOverlay('We\'re fetching your assessment now', 'Please don\'t exit or reload this page until after your test has loaded');

    let startAssignmentSubscription: Subscription;

    setTimeout(() => {
      startAssignmentSubscription.unsubscribe();
      this.spinner.showOverlay('We\'re having difficulty redirecting you Please try again',null);

        setTimeout(() => {
          this.spinner.hideOverlay();
        }, 3000);
    }, 27000);
    //just try to start the assessment session
    startAssignmentSubscription = this.mytaskServices.startAssessmentSession(myTaskHash, projectInstrumentId).subscribe(
      (data) => {
        if (null != data && data.succeeded) {
          this.startMyTasksInstrumentResponse = data;
          console.log(JSON.stringify(this.startMyTasksInstrumentResponse));
          // window.open(this.startMyTasksInstrumentResponse.extAssessmentUrl);
          //reuse the current page

          window.location.href = this.startMyTasksInstrumentResponse.extAssessmentUrl;

          //NOTE: NEVER hide overlay here, since the redirection will happen
        }
        else {
          console.error("Something error: " + data.messageToDisplay);
          this.errorMessageMapper[projectInstrumentId + ""] = data.messageToDisplay;
          this.spinner.hideOverlay();
        }
      });
  }

  startSessionForInstrument(myTaskHash: string, projectInstrumentId: number) {
    this.spinner.showOverlay('Your assessment will load soon', 'Please don\'t exit or reload this page until after your test has loaded');

    let startAssignmentSubscription: Subscription;

    setTimeout(() => {
      startAssignmentSubscription.unsubscribe();
      this.spinner.showOverlay('We\'re having difficulty redirecting you Please try again',null);

        setTimeout(() => {
          this.spinner.hideOverlay();
        }, 3000);
    }, 27000);

    //BEFORE start the assessment, let us sync the status
    startAssignmentSubscription = this.mytaskServices.syncExtAssessmentStatus(myTaskHash, projectInstrumentId, this.findSelectedInstrumentLanguage(projectInstrumentId)).subscribe(
      (data) => {
        if (null != data && data.succeeded) {
          let instrumentAssessmentStatus: string = '';
          let instrumentResumable: boolean = false;

          for (let projectInstrument of this.mytasksFrontpageResponse.projectInstruments) {
            if (projectInstrument.projectInstrumentId === projectInstrumentId) {
              //just update with the status
              console.log("status is updated: " + projectInstrument.assessmentStatus + " => " + data.status);
              projectInstrument.assessmentStatus = data.status;
              instrumentAssessmentStatus = projectInstrument.assessmentStatus;
              instrumentResumable = projectInstrument.resumable;
              break;
            }
          }

          //HOT FIX: In Progress but not resumable assessment shall not be started in the first place
          // if (instrumentAssessmentStatus === 'InProgress' && !instrumentResumable)
          // if (instrumentAssessmentStatus === 'InProgress') {
          //   this.spinner.hideOverlay();
          //   this.errorMessageMapper[projectInstrumentId + ""] = "Session already started. " +
          //     "Please contact the system admin to reset the assessment for restarting. Thanks!";
          //   return;
          // }

          this.spinner.showOverlay('Your assessment will load soon', 'Please don\'t exit or reload this page until after your test has loaded');

          //just try to start the assessment session
          this.mytaskServices.startAssessmentSession(myTaskHash, projectInstrumentId).subscribe(
            (data) => {
              if (null != data && data.succeeded) {
                this.startMyTasksInstrumentResponse = data;
                console.log(JSON.stringify(this.startMyTasksInstrumentResponse));
                // window.open(this.startMyTasksInstrumentResponse.extAssessmentUrl);
                //reuse the current page

                window.location.href = this.startMyTasksInstrumentResponse.extAssessmentUrl;

                //NOTE: NEVER hide overlay here, since the redirection will happen
              }
              else {
                console.error("Something error: " + data.messageToDisplay);
                this.errorMessageMapper[projectInstrumentId + ""] = data.messageToDisplay;
                this.spinner.hideOverlay();
              }
            });
        }
        else {
          console.error("Something error: " + data.messageToDisplay);
          this.errorMessageMapper[projectInstrumentId + ""] = data.messageToDisplay;
          this.spinner.hideOverlay();
        }
      });
  }

  requestSessionForInstrument(myTaskHash: string, projectInstrumentId: number, startImmediately: boolean) {
    this.spinner.showOverlay('We\'re fetching your assessment now', 'Please don\'t exit or reload this page until after your test has loaded');

    let startAssignmentSubscription: Subscription;

    setTimeout(() => {
      startAssignmentSubscription.unsubscribe();
      this.spinner.showOverlay('We\'re having difficulty redirecting you Please try again',null);
        setTimeout(() => {
          this.spinner.hideOverlay();
        }, 3000);
    }, 27000);

    startAssignmentSubscription = this.mytaskServices.fetchAssessmentSession(myTaskHash, projectInstrumentId,
      this.findSelectedInstrumentLanguage(projectInstrumentId)).subscribe(
        (data) => {

          if (null != data && data.succeeded) {
            this.fetchMyTasksInstrumentResponse = data;
            console.log(JSON.stringify(this.fetchMyTasksInstrumentResponse));

            if (startImmediately) {
              //start it immediately
              this.startSessionForInstrumentDirectly(myTaskHash, projectInstrumentId);
            }
            else {
              this.spinner.hideOverlay();
            }
          }
          else {
            console.error("Something error: " + data.messageToDisplay);
            this.errorMessageMapper[projectInstrumentId + ""] = data.messageToDisplay;
            this.spinner.hideOverlay();
          }
        });
  }

  getSelectedInstrumentLanguage(event, projectInstrumentId: number) {
    console.log("received event onSelectInstrumentLanguage: " + event + " for projectInstrumentId:" + projectInstrumentId);
    let instruments: ProjectInstrumentDTO[] = this.mytasksFrontpageResponse.projectInstruments;
    for (let instrument of instruments) {
      if (instrument.projectInstrumentId === projectInstrumentId) {
        let selectedLanguageCode: string = event.toString();
        instrument.defaultLangCode = selectedLanguageCode;
        break;
      }
    }

    console.log("After selecting an assessment to start...");
    console.log(JSON.stringify(this.mytasksFrontpageResponse));
  }

  findSelectedInstrumentLanguage(projectInstrumentId: number) {
    let instruments: ProjectInstrumentDTO[] = this.mytasksFrontpageResponse.projectInstruments;
    for (let instrument of instruments) {
      if (instrument.projectInstrumentId === projectInstrumentId) {
        console.log("Found an assessment language code " + instrument.defaultLangCode + "...");
        return instrument.defaultLangCode;
      }
    }

    return null;
  }
}
