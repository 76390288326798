import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs/Observable';
import { MytasksFrontpageResponse } from "./restdto/MytasksFrontpageResponse";
import { FetchMyTasksInstrumentResponse } from "./restdto/FetchMyTasksInstrumentResponse";
import { FetchMyTasksInstrumentRequest } from "./restdto/FetchMyTasksInstrumentRequest";
import { StartMyTasksInstrumentResponse } from "./restdto/StartMyTasksInstrumentResponse";
import { StartMyTasksInstrumentRequest } from "./restdto/StartMyTasksInstrumentRequest";
import {ProjectCandidatesInstrumentRequest} from "./restdto/ProjectCandidatesInstrumentRequest";
import {ResponseBasis} from "./restdto/ResponseBasis";
import {GetExternalStatusResponse} from "./restdto/GetExternalStatusResponse";
import {InstrumentErrorsRequest} from "./restdto/InstrumentErrorsRequest";

@Injectable({
  providedIn: 'root'
})
export class MytasksService {
  //TODO: QA or production
  SERVICE_PREFIX: string = "https://netresult-platform.appspot.com";

  candidateFirstName: string;
  candidateLastName: string;
  candidateEmail: string;
  myTasksHash: string;

  aboutHtml: string;
  helpHtml: string;
  prepHtml: string;

  bioDataProvided: boolean;

  companyIconUrl: string;

  public errorMessage: string;

  constructor(private http: HttpClient) {
    // if (isDevMode()) {
    //   //overwrite the service url to the local one
    //   this.SERVICE_PREFIX = "http://localhost:8086";
    // }

      this.errorMessage = null;
  }

  getMyTaskInfo(taskHash: string): Observable<MytasksFrontpageResponse> {
    let serviceUrl = this.SERVICE_PREFIX+"/mytasks/"+taskHash;

    this.myTasksHash = taskHash;

    return this.http.post<MytasksFrontpageResponse>(serviceUrl, null, {
      "responseType": "json"
    });
  }

  completeAssessmentViaDirectUrl(myTaskHash: string,  projectInstrumentId: number) {
    let serviceUrl = this.SERVICE_PREFIX+"/mytasks/completeInstrumentViaUi";

    this.myTasksHash = myTaskHash;

    let req:ProjectCandidatesInstrumentRequest = new ProjectCandidatesInstrumentRequest();
    req.myTaskHash = myTaskHash;
    req.projectInstrumentId = projectInstrumentId;

    return this.http.post<ResponseBasis>(serviceUrl, req, {
      "responseType": "json"
    });
  }

  assessmentErrorViaDirectUrl(myTaskHash: string, projectInstrumentId: number, errorCode: string) {
    let serviceUrl = this.SERVICE_PREFIX+"/mytasks/instrumentErrorsViaUi";

    this.myTasksHash = myTaskHash;

    let req:InstrumentErrorsRequest = new InstrumentErrorsRequest();
    req.myTaskHash = myTaskHash;
    req.projectInstrumentId = projectInstrumentId;
    req.errorCode = errorCode.trim();

    return this.http.post<ResponseBasis>(serviceUrl, req, {
      "responseType": "json"
    });
  }

  syncExtAssessmentStatus(myTaskHash: string, projectInstrumentId: number, assessmentLangCode: string) {
    let serviceUrl = this.SERVICE_PREFIX+"/mytasks/syncExtAssessmentStatus";

    this.myTasksHash = myTaskHash;

    let req:ProjectCandidatesInstrumentRequest = new ProjectCandidatesInstrumentRequest();
    req.myTaskHash = myTaskHash;
    req.projectInstrumentId = projectInstrumentId;
    req.assessmentLangCode = assessmentLangCode;

    return this.http.post<GetExternalStatusResponse>(serviceUrl, req, {
      "responseType": "json"
    });
  }

  fetchAssessmentSession(myTaskHash: string, projectInstrumentId: number, assessmentLangCode: string) {
    let serviceUrl = this.SERVICE_PREFIX+"/mytasks/fetchMyTasksInstrument";

    this.myTasksHash = myTaskHash;

    let fetchMyTasksInstrumentRequest: FetchMyTasksInstrumentRequest = new FetchMyTasksInstrumentRequest();
    fetchMyTasksInstrumentRequest.myTaskHash = myTaskHash;
    fetchMyTasksInstrumentRequest.projectInstrumentId = projectInstrumentId;
    fetchMyTasksInstrumentRequest.assessmentLangCode = assessmentLangCode;

    console.log("request url: "+serviceUrl+" with params: "+JSON.stringify(fetchMyTasksInstrumentRequest));

    return this.http.post<FetchMyTasksInstrumentResponse>(serviceUrl, fetchMyTasksInstrumentRequest, {
      "responseType": "json"
    });
  }

  startAssessmentSession(myTaskHash: string, projectInstrumentId: number) {
    let serviceUrl = this.SERVICE_PREFIX+"/mytasks/startInstrumentSession";

    this.myTasksHash = myTaskHash;

    let startMyTasksInstrumentRequest: StartMyTasksInstrumentRequest = new StartMyTasksInstrumentRequest();
    startMyTasksInstrumentRequest.myTaskHash = myTaskHash;
    startMyTasksInstrumentRequest.projectInstrumentId = projectInstrumentId;

    console.log("request url: "+serviceUrl+" with params: "+JSON.stringify(startMyTasksInstrumentRequest));

    return this.http.post<StartMyTasksInstrumentResponse>(serviceUrl, startMyTasksInstrumentRequest, {
      "responseType": "json"
    });
  }

  uploadBiodata(myTaskHash: string, bioDataMapper: any) {
      let serviceUrl = this.SERVICE_PREFIX+"/mytasks/"+myTaskHash+"/uploadBioData";

      console.log("request url: "+serviceUrl+" with params: "+JSON.stringify(bioDataMapper));

      return this.http.post<StartMyTasksInstrumentResponse>(serviceUrl, bioDataMapper, {
          "responseType": "json"
      });
  }
}
