import {Component, Input, OnInit} from '@angular/core';
import {SpinnerOverlayService} from '../services/spinneroverlay.service';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {

  constructor(public spinner: SpinnerOverlayService) { }

  ngOnInit() {

  }

  goTo(url: string){
    window.location.href = url;
  }
}
