import {Component, OnInit, ViewEncapsulation, AfterViewInit, Input, Output, EventEmitter} from '@angular/core';
import {
  AbstractControl,
  FormBuilder, FormControl,
  FormGroup, Validators
} from '@angular/forms';
import {MytasksService} from '../services/mytasks.service';
import {SpinnerOverlayService} from '../services/spinneroverlay.service';

/*
 * Validators
 */
function emailAddressValidation(emailAddressControl: FormControl): { [s: string]: boolean } {
  if (emailAddressControl.value) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(emailAddressControl.value)) {
      return {invalidEmail: true};
    }
  }
}

function ageValidation(ageControl: FormControl): { [s: string]: boolean } {
  if (ageControl.value) {
    const age = parseInt(ageControl.value);
    if (ageControl.value != age) {
      return {invalidAge: true};
    }
  }
}

declare let $;
declare let M;
@Component({
  selector: 'app-bio-data',
  templateUrl: './bio-data.component.html',
  styleUrls: ['./bio-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BioDataComponent implements OnInit, AfterViewInit {

  bioDataForm: FormGroup;

  @Input() isAdmin: boolean;
  
  ethnicities = [
    {
      label: 'No ethnicity selected',
      value: ''
    },
    {
      label: 'White Australian',
      value: 'White Australian'
    },
    {
      label: 'East Asian',
      value: 'East Asian'
    },
    {
      label: 'South Asian',
      value: 'South Asian'
    },
    {
      label: 'Middle Eastern',
      value: 'Middle Eastern'
    },
    {
      label: 'Mixed Race',
      value: 'Mixed Race'
    },
    {
      label: 'Indigenous Australian or Torres Strait Islander',
      value: 'Indigenous Australian or Torres Strait Islander'
    },
    {
      label: 'Pacific Islander',
      value: 'Pacific Islander'
    },
  ];

  genders = [
    {
      'label': 'No gender selected',
      'value': ''
    },
    {
      'label': 'Female',
      'value': 'Female'
    },
    {
      'label': 'Male',
      'value': 'Male'
    },
    {
      'label': 'Non-binary',
      'value': 'Non-binary'
    },
    {
      'label': 'Unspecified',
      'value': 'Unspecified'
    }
  ];

  qualifications = [
    {
      'label': 'No qualification selected',
      'value': ''
    },
    {
      'label': 'No formal qualifications',
      'value': 'No formal qualifications'
    },
    {
      'label': 'Less than High School',
      'value': 'Less than High School'
    },
    {
      'label': 'High School',
      'value': 'High School'
    },
    {
      'label': 'Some College/ Associate Degree',
      'value': 'Some College/ Associate Degree'
    },
    {
      'label': 'Bachelor\'s Degree',
      'value': 'Bachelor\'s Degree'
    },
    {
      'label': 'First degree',
      'value': 'First degree'
    },
    {
      'label': 'Master\'s Degree',
      'value': 'Master\'s Degree'
    },
    {
      'label': 'Postgraduate degree',
      'value': 'Postgraduate degree'
    },
    {
      'label': 'Professional qualification',
      'value': 'Professional qualification'
    },
    {
      'label': 'Ph.D.',
      'value': 'Ph.D.'
    },
    {
      'label': 'Other',
      'value': 'Other'
    }
  ];

  workingexps = [
    {
      'label': 'No experience selected',
      'value': ''
    },
    {
      'label': '< 6 months',
      'value': '< 6 months'
    },
    {
      'label': '6-12 months',
      'value': '6-12 months'
    },
    {
      'label': '1-2 years',
      'value': '1-2 years'
    },
    {
      'label': '3-5 years',
      'value': '3-5 years'
    },
    {
      'label': '6-9 years',
      'value': '6-9 years'
    },
    {
      'label': '10-20 years',
      'value': '10-20 years'
    },
    {
      'label': '> 20 years',
      'value': '> 20 years'
    },
    {
      'label': 'Not applicable',
      'value': 'Not applicable'
    },
    {
      'label': 'Other',
      'value': 'Other'
    }
  ];

  empstatuses = [
    {
      'label': 'No employment status selected',
      'value': ''
    },
    {
      'label': 'Permanent Full-time',
      'value': 'Permanent Full-time'
    },
    {
      'label': 'Permanent Part-time',
      'value': 'Permanent Part-time'
    },
    {
      'label': 'Temporary/Contract Full-time',
      'value': 'Temporary/Contract Full-time'
    },
    {
      'label': 'Temporary/Contract Part-time',
      'value': 'Temporary/Contract Part-time'
    },
    {
      'label': 'Self-employed',
      'value': 'Self-employed'
    },
    {
      'label': 'Retired',
      'value': 'Retired'
    },
    {
      'label': 'Not currently employed - Student',
      'value': 'Not currently employed - Student'
    },
    {
      'label': 'Not currently employed - Other',
      'value': 'Not currently employed - Other'
    },
    {
      'label': 'Other',
      'value': 'Other'
    }
  ];

  workfunctions = [
    {
      'label': 'No work function selected',
      'value': ''
    },
    {
      'label': 'Administration',
      'value': 'Administration'
    },
    {
      'label': 'Call Centre',
      'value': 'Call Centre'
    },
    {
      'label': 'Construction',
      'value': 'Construction'
    },
    {
      'label': 'Consulting',
      'value': 'Consulting'
    },
    {
      'label': 'Counter Service',
      'value': 'Counter Service'
    },
    {
      'label': 'Culture',
      'value': 'Culture'
    },
    {
      'label': 'Customer Service',
      'value': 'Customer Service'
    },
    {
      'label': 'Data Processing',
      'value': 'Data Processing'
    },
    {
      'label': 'Design',
      'value': 'Design'
    },
    {
      'label': 'Distribution',
      'value': 'Distribution'
    },
    {
      'label': 'Education',
      'value': 'Education'
    },
    {
      'label': 'Engineering',
      'value': 'Engineering'
    },
    {
      'label': 'Entertainment',
      'value': 'Entertainment'
    },
    {
      'label': 'Executive',
      'value': 'Executive'
    },
    {
      'label': 'Finance',
      'value': 'Finance'
    },
    {
      'label': 'Health',
      'value': 'Health'
    },
    {
      'label': 'Hospitality',
      'value': 'Hospitality'
    },
    {
      'label': 'Human Resources',
      'value': 'Human Resources'
    },
    {
      'label': 'Humanities/Social Sciences',
      'value': 'Humanities/Social Sciences'
    },
    {
      'label': 'Information Technology',
      'value': 'Information Technology'
    },
    {
      'label': 'Law',
      'value': 'Law'
    },
    {
      'label': 'Leisure',
      'value': 'Leisure'
    },
    {
      'label': 'Line Management',
      'value': 'Line Management'
    },
    {
      'label': 'Marketing',
      'value': 'Marketing'
    },
    {
      'label': 'Media',
      'value': 'Media'
    },
    {
      'label': 'Nature',
      'value': 'Nature'
    },
    {
      'label': 'Operations',
      'value': 'Operations'
    },
    {
      'label': 'Operations / Quality Assurance',
      'value': 'Operations / Quality Assurance'
    },
    {
      'label': 'Production',
      'value': 'Production'
    },
    {
      'label': 'Public Relations',
      'value': 'Public Relations'
    },
    {
      'label': 'Public Safety',
      'value': 'Public Safety'
    },
    {
      'label': 'Purchasing',
      'value': 'Purchasing'
    },
    {
      'label': 'Research',
      'value': 'Research'
    },
    {
      'label': 'Sales',
      'value': 'Sales'
    },
    {
      'label': 'Science',
      'value': 'Science'
    },
    {
      'label': 'Security',
      'value': 'Security'
    },
    {
      'label': 'Transport',
      'value': 'Transport'
    },
    {
      'label': 'Travel',
      'value': 'Travel'
    },
    {
      'label': 'Welfare',
      'value': 'Welfare'
    },
    {
      'label': 'Other',
      'value': 'Other'
    },
    {
      'label': 'Not applicable',
      'value': 'Not applicable'
    }
  ];

  professions = [
    {
      'label': 'No management responsibility selected',
      'value': ''
    },
    {
      'label': 'Individual Contributor - Non-Professional',
      'value': 'Individual Contributor - Non-Professional'
    },
    {
      'label': 'Team Leader',
      'value': 'Team Leader'
    },
    {
      'label': 'Manager',
      'value': 'Manager'
    },
    {
      'label': 'Senior Manager',
      'value': 'Senior Manager'
    },
    {
      'label': 'Functional Manager',
      'value': 'Functional Manager'
    },
    {
      'label': 'Business Manager',
      'value': 'Business Manager'
    },
    {
      'label': 'Group Manager',
      'value': 'Group Manager'
    },
    {
      'label': 'Enterprise/Corporate Manager',
      'value': 'Enterprise/Corporate Manager'
    },
    {
      'label': 'Not applicable',
      'value': 'Not applicable'
    }
  ];

  durationEmploies = [
    {
      'label': 'No time with current employer selected',
      'value': ''
    },
    {
      'label': '< 6 months',
      'value': '< 6 months'
    },
    {
      'label': '6-12 months',
      'value': '6-12 months'
    },
    {
      'label': '1-2 years',
      'value': '1-2 years'
    },
    {
      'label': '3-5 years',
      'value': '3-5 years'
    },
    {
      'label': '6-9 years',
      'value': '6-9 years'
    },
    {
      'label': '10-20 years',
      'value': '10-20 years'
    },
    {
      'label': '> 20 years',
      'value': '> 20 years'
    },
    {
      'label': 'Not applicable',
      'value': 'Not applicable'
    },
    {
      'label': 'Other',
      'value': 'Other'
    }
  ];

  languages = [
    {
      'value': 'ar-001',
      'label': 'العربية'
    },
    {
      'value': 'ar-AE',
      'label': 'العربية (الإمارات العربية المتحدة)‏‏'
    },
    {
      'value': 'ar-OM',
      'label': 'العربية (عمان)‏‏'
    },
    {
      'value': 'ar-SA',
      'label': 'العربية (المملكة العربية السعودية)‏‏'
    },
    {
      'value': 'az-Latn-AZ',
      'label': 'Azərbaycan –dili (Azərbaycanca)‎'
    },
    {
      'value': 'bg-BG',
      'label': 'Български (България)‎'
    },
    {
      'value': 'cs-CZ',
      'label': 'Čeština (Česká republika)'
    },
    {
      'value': 'da-DK',
      'label': 'Dansk (Danmark)‎'
    },
    {
      'value': 'de-DE',
      'label': 'Deutsch (Deutschland)'
    },
    {
      'value': 'el-GR',
      'label': 'Ελληνικά (Ελλάδα)‎'
    },
    {
      'value': 'en-GB',
      'label': 'English (United Kingdom)‎'
    },
    {
      'value': 'es-ES',
      'label': 'Español (España)‎'
    },
    {
      'value': 'es-MX',
      'label': 'Español (México)'
    },
    {
      'value': 'fi-FI',
      'label': 'Suomi (Suomi)'
    },
    {
      'value': 'fr-FR',
      'label': 'Français (France)'
    },
    {
      'value': 'hi-IN',
      'label': 'हिंहिंदी (भारत)'
    },
    {
      'value': 'hu-HU',
      'label': 'Magyar (Magyarország)'
    },
    {
      'value': 'id-ID',
      'label': 'Bahasa Indonesia (Indonesia)‎'
    },
    {
      'value': 'it-IT',
      'label': 'Italiano (Italia)‎'
    },
    {
      'value': 'ja-JP',
      'label': '日本語 (日本)‎'
    },
    {
      'value': 'ka-GE',
      'label': 'ქართული (საქართველო)‎'
    },
    {
      'value': 'kk-KZ',
      'label': 'Қазақ (Қазақстан)‎'
    },
    {
      'value': 'ko-KR',
      'label': '한국어 (대한민국)‎‎'
    },
    {
      'value': 'ms-MY',
      'label': 'Bahasa Malaysia (Malaysia)‎‎'
    },
    {
      'value': 'nb-NO',
      'label': 'Norsk (Bokmål) (Norge)'
    },
    {
      'value': 'nl-NL',
      'label': 'Nederlands (Nederland)‎'
    },
    {
      'value': 'pl-PL',
      'label': 'Polski (Polska)'
    },
    {
      'value': 'pt-BR',
      'label': 'Português (Brasil)'
    },
    {
      'value': 'pt-PT',
      'label': 'Português (Portugal)'
    },
    {
      'value': 'ro-RO',
      'label': 'Română (România)'
    },
    {
      'value': 'ru-RU',
      'label': 'Pусский'
    },
    {
      'value': 'sk-SK',
      'label': 'Slovenčina (Slovenská republika)'
    },
    {
      'value': 'sv-SE',
      'label': 'Svenska (Sverige)'
    },
    {
      'value': 'th-TH',
      'label': 'ไทย (ไทย)'
    },
    {
      'value': 'tr-TR',
      'label': 'Türkçe (Türkiye)'
    },
    {
      'value': 'ur-PK',
      'label': 'اُردو (پاکستان)'
    },
    {
      'value': 'vi-VN',
      'label': 'Tiếng Việt Nam (Việt Nam)'
    },
    {
      'value': 'zh-CN',
      'label': '中文(简体) (中华人民共和国)'
    },
    {
      'value': 'zh-HK',
      'label': '中文(繁體) (香港特別行政區)'
    },
    {
      'value': 'zh-TW',
      'label': '中文(繁體) (台灣)‎'
    }
  ];

  formBuilder: FormBuilder;

  emailFilled: boolean;
  candidateFirstNameFilled: boolean;
  candidateLastNameFilled: boolean;

  emailAddressControl: AbstractControl;
  firstNameControl: AbstractControl;
  lastNameControl: AbstractControl;
  ageControl: AbstractControl;

  // @Input() candidateLastName: string;
  // @Input() candidateFirstName: string;
  // @Input() candidateEmail: string;

  @Output()
  onBioDataStatusLoaded = new EventEmitter();

  constructor(formBuilder: FormBuilder, private spinner: SpinnerOverlayService, private mytaskServices: MytasksService) {
    this.formBuilder = formBuilder;
    this.bioDataForm = this.formBuilder.group({
      'emailaddress': ['', Validators.compose([Validators.required, emailAddressValidation])],
      'firstname': ['', Validators.required],
      'lastname': ['', Validators.required],

      // 'title': [''],
      'ethnicitySelection': [this.ethnicities[0].value],
      'genderSelection': [this.genders[0].value],
      'age': ['', Validators.compose([ageValidation])],
      'qualificationSelection': [this.qualifications[0].value],
      'otherqualification': [''],
      'workingexpSelection': [this.workingexps[0].value],
      'otherworkexperience': [''],
      'jobTitle': [''],
      'otherstatus' : [''],
      'otherworkfunc' : [''],
      'othertimeperiod': [''],
      'otherlanguage': [''],
      'empstatusSelection': [this.empstatuses[0].value],
      'workfuncSelection': [this.workfunctions[0].value],
      'professionalSelection': [this.professions[0].value],
      'durationemployedSelection': [this.durationEmploies[0].value],
      'languageSelection': [this.languages[10].value]
    });

    this.emailAddressControl = this.bioDataForm.controls['emailaddress'];
    this.firstNameControl = this.bioDataForm.controls['firstname'];
    this.lastNameControl = this.bioDataForm.controls['lastname'];
    this.ageControl = this.bioDataForm.controls['age'];
  }

  private initDefaultValuesForFieldsIfAny() {
      // initialize the default data for the mandatory items
      console.log('initDefaultValuesForFieldsIfAny: ' + this.mytaskServices.candidateEmail + ' ' +
          this.mytaskServices.candidateFirstName + ' ' + this.mytaskServices.candidateLastName);

      if (this.mytaskServices.candidateEmail &&
          this.mytaskServices.candidateFirstName &&
          this.mytaskServices.candidateLastName) {

        console.log('existing email address: [' + this.bioDataForm.controls['emailaddress'].value.toString() + ']');
          if (!this.bioDataForm.controls['emailaddress'].value ||
              this.bioDataForm.controls['emailaddress'].value.toString().trim().length === 0) {
              this.bioDataForm.controls['emailaddress'].setValue(this.mytaskServices.candidateEmail);
              this.emailFilled = true;
          }

          console.log('existing firstname: [' + this.bioDataForm.controls['firstname'].value.toString() + ']');
          if (!this.bioDataForm.controls['firstname'].value ||
              this.bioDataForm.controls['firstname'].value.toString().trim().length === 0) {
              this.bioDataForm.controls['firstname'].setValue(this.mytaskServices.candidateFirstName);
              this.candidateFirstNameFilled = true;
          }

          console.log('existing lastname: [' + this.bioDataForm.controls['lastname'].value.toString() + ']');
          if (!this.bioDataForm.controls['lastname'].value ||
              this.bioDataForm.controls['lastname'].value.toString().trim().length === 0) {
              this.bioDataForm.controls['lastname'].setValue(this.mytaskServices.candidateLastName);
              this.candidateLastNameFilled = true;
          }
      }
  }

  ngOnInit() {
    this.initDefaultValuesForFieldsIfAny();
  }

  ngAfterViewInit() {
    M.FormSelect.init($('.modal-body select') , {});
  }

  openBioDataModal() {
    console.log('bio data button is clicked');
    this.initDefaultValuesForFieldsIfAny();
    $('#buttonmodal').modal();
  }

  onSubmit(formData: any): void {

    if (!this.mytaskServices.myTasksHash) {
      console.error('error my tasks hash!');
      // TODO: show some error message in the modal popup
      return;
    }

    this.spinner.showOverlay('Uploading your bio data...', 'Please wait a moment');

    // just try to start the assessment session
    this.mytaskServices.uploadBiodata(this.mytaskServices.myTasksHash, formData).subscribe(
      (data) => {
          this.spinner.hideOverlay();

          if (null != data && data.succeeded) {
              this.onBioDataStatusLoaded.emit(true);
          }
          else {
              console.error('Something error: ' + data.messageToDisplay);
              // TODO: show some error message in the modal popup
          }

          $('#buttonmodal').modal('hide'); // hide the modal
      });
  }
}
