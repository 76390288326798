import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routingModule } from './app.routing';
import { NgSelectModule } from '@ng-select/ng-select';


import { AppComponent } from './app.component';
import { InstrumentComponent } from './instrument/instrument.component';
import { BioDataComponent } from './bio-data/bio-data.component';
import { LanguageselectboxComponent } from './languageselectbox/languageselectbox.component';
import { OverlayComponent } from './overlay/overlay.component';
import { SpinnerOverlayService } from './services/spinneroverlay.service';
import { MyTasksComponent } from './my-tasks/my-tasks.component';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    InstrumentComponent,
    BioDataComponent,
    LanguageselectboxComponent,
    OverlayComponent,
    MyTasksComponent
  ],
  imports: [
    BrowserModule,
    routingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule 
  ],
  providers: [
    SpinnerOverlayService,
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
