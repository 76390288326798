import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  overlay: boolean = false;
  title: string;
  subtitle: string;
  buttonURL: string;

   constructor() {
   }

   showOverlay(title: string, subtitle: string, buttonURL: string = null) {
      this.overlay = true;
      this.title = title;
      this.subtitle = subtitle;
      this.buttonURL = buttonURL;
   }

   hideOverlay() {
    this.overlay = false;
  }
}
