import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MytasksService} from "../services/mytasks.service";
import {SpinnerOverlayService} from "../services/spinneroverlay.service";

declare let $;

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.css']
})
export class MyTasksComponent implements OnInit {

  taskHash: string;
  instrumentId: string;
  errorCode: string;
  isAdmin: string;

  constructor(private route: ActivatedRoute, private spinner: SpinnerOverlayService, private mytaskServices: MytasksService) {
    this.route.params.subscribe(params => {
      if (null != params && null != params.hash) {
        this.taskHash = params.hash;
        console.log("Got path params: "+JSON.stringify(params));
        this.instrumentId = params.instrumentId;

        this.route.queryParams.subscribe(queryParams => {
          if (null != queryParams) {
            this.isAdmin = queryParams.admin;
            this.errorCode = queryParams.error;
            console.log("Got error code: "+this.errorCode);
          }
        });
      }
    });
  }

  ngOnInit() {
    this.spinner.showOverlay('Loading My Tasks page...', 'Please wait for a while');
  }

  getMyTaskError() {
      return this.mytaskServices.errorMessage;
  }

  getCandidateFirstName() {
    return this.mytaskServices.candidateFirstName;
  }

  getCandidateLastName() {
    return this.mytaskServices.candidateLastName;
  }

  getCandidateEmail() {
    return this.mytaskServices.candidateEmail;
  }

  getAbouotHTML(){
    return this.mytaskServices.aboutHtml;
  }

  getPrepHTML(){
    return this.mytaskServices.prepHtml;
  }

  getHelpHTML(){
    return this.mytaskServices.helpHtml;
  }

  getCompanyIconUrl(){
    return this.mytaskServices.companyIconUrl;
  }

  copeWithBioDataStatus(event) {
    if (event) { //if already provided
      //just disable the bio data section
     $('.feature').css('opacity', 0.3);
     $('.feature .btn').prop('disabled', true);
    }
  }

  copeWithBioDataStatusInstrument(event) {

  }
}
