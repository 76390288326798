// public class FetchMyTasksInstrumentRequest extends RequestBasis {
//   String myTaskHash;
//   Long projectInstrumentId;
//   String assessmentLangCode;

import { RequestBasis } from "./RequestBasis";

export class StartMyTasksInstrumentRequest extends RequestBasis {
  myTaskHash: string;
  projectInstrumentId: number;

}
