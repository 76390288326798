import { ModuleWithProviders } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import {MyTasksComponent} from "./my-tasks/my-tasks.component";

const routes: Routes = [
  { path: ":hash/:instrumentId", component: MyTasksComponent },
  { path: ":hash", component: MyTasksComponent }
  // { path: '',  redirectTo: ':hash', pathMatch: 'full' }
];

export const routingModule: ModuleWithProviders = RouterModule.forRoot(routes);
